import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5da70994"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "home-box"
};
const _hoisted_2 = {
  class: "tab-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_people = _resolveComponent("people");
  const _component_Error = _resolveComponent("Error");
  return $data.productList.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_people, {
    list: $data.productList,
    type: "people",
    onGetlist: $options.getNext
  }, null, 8, ["list", "onGetlist"])])])) : (_openBlock(), _createBlock(_component_Error, {
    key: 1,
    message: "这里没有更多的信息",
    img: "icon_nopeople"
  }));
}