import Error from "@@/common/Error.vue";
import { followList } from "@api/index";
import { mapState } from "vuex";
import people from "@@/home/people.vue";
import { env } from "md-base-tools/env";
// import header from "md-base-tools/header";
export default {
  components: {
    people,
    Error
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      loading: true,
      is_next: true,
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      pall: false,
      showError: false,
      authInfoType: false,
      orderListShow: false,
      message: "",
      op_type: 0,
      nft_num: 0,
      stock_hash: "",
      receive_code: "",
      status: "",
      status_name: "",
      user_info: {},
      orderData: {},
      productList: [],
      toUserid: "",
      page: 1
    };
  },
  async mounted() {
    this.toUserid = this.$route.params.id;
    // eslint-disable-next-line no-undef
    userHeader.titleText("关注的人");
    await this.followList();
  },
  methods: {
    async getNext(e) {
      if (e === 1) {
        this.productList = [];
      }
      this.page = e;
      if (this.is_next || e === 1) {
        await this.followList();
      }
    },
    async followList() {
      const {
        status,
        data
      } = await followList({
        user_id: this.userId,
        to_user_id: this.userId,
        page: this.page,
        page_size: 10
      });
      if (status == 0) {
        this.is_next = data.is_next;
        this.productList.push(...data.list);
      }
    }
  }
};