import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5cd9800f"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "name"
};
const _hoisted_4 = {
  key: 0,
  class: "tips"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "bottom-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_list = _resolveComponent("list");
  const _component_pull_refresh = _resolveComponent("pull-refresh");
  return _openBlock(), _createBlock(_component_pull_refresh, {
    modelValue: $data.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.refreshing = $event),
    onRefresh: $options.onRefresh
  }, {
    default: _withCtx(() => [_createVNode(_component_list, {
      loading: $data.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = $event => $data.loading = $event),
      finished: $data.finished,
      onLoad: $options.onLoad
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(['list-box', {
          'has-bottom-text': $props.bottom_text
        }])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => $options.toUserhome(item),
          class: "list-item"
        }, [_createElementVNode("img", {
          src: item.icon ? item.icon : 'https://s.moimg.net/new/images/headPic.png'
        }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(item.nickname), 1), $props.type === 'project' ? (_openBlock(), _createElementBlock("div", _hoisted_4, "已支持" + _toDisplayString(item.support_total) + "个项目", 1)) : _createCommentVNode("", true)]), $props.type === 'project' ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _withModifiers($event => $options.like(item), ["stop"]),
          class: _normalizeClass(['btn', {
            gray: item.is_follow
          }])
        }, [_ctx.userId != item.user_id ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode(_toDisplayString($data.relationMap[item.is_follow ? '1' : '2']), 1)], 64)) : _createCommentVNode("", true)], 10, _hoisted_5)) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _withModifiers($event => $options.like(item), ["stop"]),
          class: _normalizeClass(['btn', {
            gray: item.relation == 1 || item.relation == 3
          }])
        }, _toDisplayString($data.relationMap[item.relation]), 11, _hoisted_6))], 8, _hoisted_1);
      }), 128)), $props.bottom_text ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.bottom_text), 1)) : _createCommentVNode("", true)], 2)]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]);
}