import MDRouter from "@/service/router";
import {
// eslint-disable-next-line no-unused-vars
Image as VImage, List, PullRefresh,
// eslint-disable-next-line no-unused-vars
Progress as VProgress, showDialog } from "vant";
import { mapState } from "vuex";
import { follow } from "@/api/index";
import { mdToast } from "../../utils/tools";
export default {
  props: {
    list: {
      type: Array || Object,
      default: []
    },
    type: {
      type: String,
      // people: 个人中心； project: 众筹详情-支持者列表
      default: "people"
    },
    bottom_text: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  components: {
    List,
    PullRefresh
  },
  data() {
    return {
      relationMap: {
        1: "已关注",
        2: "+ 关注",
        3: "互相关注",
        '-2': "+ 关注",
        '-1': "+ 关注"
      },
      loading: true,
      is_next: true,
      page: 2,
      refreshing: false,
      finished: false,
      fit: "cover",
      timer: null
    };
  },
  methods: {
    toUserhome(item) {
      MDRouter.toUserhome(item.user_id || item.id);
    },
    like(item) {
      if (item.relation == 1 || item.relation == 3 || item.is_follow) {
        showDialog({
          title: "确定不再关注Ta吗?",
          message: ""
        }).then(() => {
          this.followPeople(item);
        }).catch(() => {
          // on cancel
          throw new Error();
        });
      } else {
        this.followPeople(item);
      }
    },
    async followPeople(item) {
      let msg = '';
      let type = 1;
      let relation = 1;
      const relationType = this.type == 'project' ? item.is_follow ? 1 : 2 : item.relation;
      switch (relationType) {
        case 1:
          msg = '取消成功';
          type = 2;
          relation = -2;
          break;
        case 3:
          msg = '取消成功';
          type = 2;
          relation = 2;
          break;
        case 2:
          msg = '关注成功';
          type = 1;
          relation = 3;
          break;
        case -2:
          msg = '关注成功';
          type = 1;
          relation = 1;
          break;
        case -1:
          msg = '关注成功';
          type = 1;
          relation = 1;
          break;
      }
      const {
        status
      } = await follow({
        to_user_id: item.user_id || item.id,
        type: type // 1-关注 2-取消
      });
      if (status == 0) {
        if (this.type == 'project') {
          item.is_follow = relation == 1 || relation == 3 ? true : false;
        } else {
          item.relation = relation;
        }
        mdToast(msg);
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.page = 1;
        this.refreshing = false;
        this.loading = true;
      }
      if (this.loading) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$emit("getlist", this.page);
          this.page++;
        }, 100);
      }
    },
    onRefresh() {
      this.finished = false;
      this.onLoad();
    },
    closePopup() {
      this.$emit("update:show", false);
    }
  }
};